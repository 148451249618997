<template>
  <el-card class="v_task_money_config">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="年龄" name="age"></el-tab-pane>
        <el-tab-pane label="花呗和京豆开通" name="credit_pay"></el-tab-pane>
        <el-tab-pane label="包邮邮费" name="free"></el-tab-pane>
        <el-tab-pane label="货比" name="huobi"></el-tab-pane>
        <el-tab-pane label="副宝贝浏览" name="liulan"></el-tab-pane>
        <el-tab-pane label="淘宝附加商品" name="tb_fjsp"></el-tab-pane>
        <el-tab-pane label="京东附加商品" name="jd_fjsp"></el-tab-pane>
        <el-tab-pane label="拼多多附加商品金额" name="pdd_fjsp"></el-tab-pane>
        <el-tab-pane label="淘宝信誉等级" name="xinyu"></el-tab-pane>
        <el-tab-pane label="京东信誉等级" name="jd_xinyu"></el-tab-pane>
        <el-tab-pane label="主宝贝浏览" name="main_liulan"></el-tab-pane>
        <el-tab-pane label="地域限制" name="province"></el-tab-pane>
        <el-tab-pane label="性别限制" name="sex"></el-tab-pane>
        <el-tab-pane label="任务类型" name="taskTypeList"></el-tab-pane>
        <el-tab-pane label="商家审号" name="task_pass"></el-tab-pane>
        <el-tab-pane label="拍下时间" name="xdsj"></el-tab-pane>
        <div class="v-task-config-show-box">
          <task-money-configage ref="TaskMoneyConfigage" v-show="activeName === 'age'"/>
        
          <task-money-configcreditpay ref="TaskMoneyConfigcreditpay" v-show="activeName === 'credit_pay'"/>

          <task-money-configfree ref="TaskMoneyConfigfree" v-show="activeName === 'free'"/>

          <task-money-confighuobi ref="TaskMoneyConfighuobi" v-show="activeName === 'huobi'"/>

          <task-money-configliulan ref="TaskMoneyConfigliulan" v-show="activeName === 'liulan'"/>

          <task-money-configtbfjsp ref="TaskMoneyConfigtbfjsp" v-show="activeName === 'tb_fjsp'"/>

          <task-money-configjdfjsp ref="TaskMoneyConfigjdfjsp" v-show="activeName === 'jd_fjsp'"/>

          <task-money-configpddfjsp ref="TaskMoneyConfigpddfjsp" v-show="activeName === 'pdd_fjsp'"/>

          <task-money-configxinyu ref="TaskMoneyConfigxinyu" v-show="activeName === 'xinyu'"/>

          <task-money-configjdxinyu ref="TaskMoneyConfigjdxinyu" v-show="activeName === 'jd_xinyu'"/>

          <task-money-configmainliulan ref="TaskMoneyConfigmainliulan" v-show="activeName === 'main_liulan'"/>

          <task-money-configprovince ref="TaskMoneyConfigprovince" v-show="activeName === 'province'"/>

          <task-money-configsex ref="TaskMoneyConfigsex" v-show="activeName === 'sex'"/>

          <task-money-configtask-type-list ref="TaskMoneyConfigtaskTypeList" v-show="activeName === 'taskTypeList'"/>

          <task-money-configtaskpass ref="TaskMoneyConfigtaskpass" v-show="activeName === 'task_pass'"/>

          <task-money-configxdsj ref="TaskMoneyConfigxdsj" v-show="activeName === 'xdsj'"/>

        </div>
    </el-tabs>
  </el-card>
</template>

<script>
  import TaskMoneyConfigage from './TaskMoneyConfigage.vue'
  import TaskMoneyConfigcreditpay from './TaskMoneyConfigcreditpay.vue'
  import TaskMoneyConfigfree from './TaskMoneyConfigfree.vue'
  import TaskMoneyConfighuobi from './TaskMoneyConfighuobi.vue'
  import TaskMoneyConfigliulan from './TaskMoneyConfigliulan.vue'
  import TaskMoneyConfigtbfjsp from './TaskMoneyConfigtbfjsp.vue'
  import TaskMoneyConfigjdfjsp from './TaskMoneyConfigjdfjsp.vue'
  import TaskMoneyConfigpddfjsp from './TaskMoneyConfigpddfjsp.vue'
  import TaskMoneyConfigxinyu from './TaskMoneyConfigxinyu.vue'
  import TaskMoneyConfigjdxinyu from './TaskMoneyConfigjdxinyu.vue'
  import TaskMoneyConfigmainliulan from './TaskMoneyConfigmainliulan.vue'
  import TaskMoneyConfigprovince from './TaskMoneyConfigprovince.vue'
  import TaskMoneyConfigsex from './TaskMoneyConfigsex.vue'
  import TaskMoneyConfigtaskTypeList from './TaskMoneyConfigtaskTypeList.vue'
  import TaskMoneyConfigtaskpass from './TaskMoneyConfigtaskpass.vue'
  import TaskMoneyConfigxdsj from './TaskMoneyConfigxdsj.vue'
  // age  年龄限制
  // credit_pay  花呗和京豆开通用户
  // free  快递不包邮的邮费
  // huobi  不货比
  // liulan  不浏览
  // tb_fjsp  淘宝附加商品金额
  // jd_fjsp  京东附加商品金额
  // pdd_fjsp  拼多多附加商品金额
  // xinyu  二心以上
  // jd_xinyu  不限
  // main_liulan  浏览宝贝评价
  // province  地域限制
  // sex  性别限制
  // taskTypeList  普通好评任务
  // task_pass  商家任务审核服务
  // xdsj  立即拍下(免费)
  export default {
    components: { TaskMoneyConfigage, 
    TaskMoneyConfigcreditpay, 
    TaskMoneyConfigfree, 
    TaskMoneyConfighuobi, 
    TaskMoneyConfigliulan, 
    TaskMoneyConfigtbfjsp, 
    TaskMoneyConfigjdfjsp,
    TaskMoneyConfigpddfjsp,
    TaskMoneyConfigxinyu,
    TaskMoneyConfigjdxinyu,
    TaskMoneyConfigmainliulan,
    TaskMoneyConfigprovince,
    TaskMoneyConfigsex,
    TaskMoneyConfigtaskTypeList,
    TaskMoneyConfigtaskpass,
    TaskMoneyConfigxdsj },
    data() {
      return {
        activeName: 'age'
      }
    },
    mounted() {
      this.$refs.TaskMoneyConfigage.onShow(this.activeName)
    },
    methods: {
      handleClick() {
        if(this.activeName === 'age') {
          this.$refs.TaskMoneyConfigage.onShow(this.activeName)
        }else if(this.activeName === 'credit_pay') {
          this.$refs.TaskMoneyConfigcreditpay.onShow(this.activeName)
        }else if(this.activeName === 'free') {
          this.$refs.TaskMoneyConfigfree.onShow(this.activeName)
        }else if(this.activeName === 'huobi') {
          this.$refs.TaskMoneyConfighuobi.onShow(this.activeName)
        }else if(this.activeName === 'liulan') {
          this.$refs.TaskMoneyConfigliulan.onShow(this.activeName)
        }else if(this.activeName === 'tb_fjsp') {
          this.$refs.TaskMoneyConfigtbfjsp.onShow(this.activeName)
        }else if(this.activeName === 'jd_fjsp') {
          this.$refs.TaskMoneyConfigjdfjsp.onShow(this.activeName)
        }else if(this.activeName === 'pdd_fjsp') {
          this.$refs.TaskMoneyConfigpddfjsp.onShow(this.activeName)
        }else if(this.activeName === 'xinyu') {
          this.$refs.TaskMoneyConfigxinyu.onShow(this.activeName)
        }else if(this.activeName === 'jd_xinyu') {
          this.$refs.TaskMoneyConfigjdxinyu.onShow(this.activeName)
        }else if(this.activeName === 'main_liulan') {
          this.$refs.TaskMoneyConfigmainliulan.onShow(this.activeName)
        }else if(this.activeName === 'province') {
          this.$refs.TaskMoneyConfigprovince.onShow(this.activeName)
        }else if(this.activeName === 'sex') {
          this.$refs.TaskMoneyConfigsex.onShow(this.activeName)
        }else if(this.activeName === 'taskTypeList') {
          this.$refs.TaskMoneyConfigtaskTypeList.onShow(this.activeName)
        }else if(this.activeName === 'task_pass') {
          this.$refs.TaskMoneyConfigtaskpass.onShow(this.activeName)
        }else if(this.activeName === 'xdsj') {
          this.$refs.TaskMoneyConfigxdsj.onShow(this.activeName)
        }
    }
  }
}
</script>

<style lang="scss">
</style>