<template>
  <div class="s_task_config_base g-flex-column" v-show="show">
    <el-form ref="form" label-width="300px" label-position="right">
      <el-row :gutter="24" v-for="(item,index) in formList" :key="index">
        <el-col :span="10">
          <el-form-item :label="item.title + '（元）：'">
            <el-input v-model="item.price"></el-input>
          </el-form-item>
        </el-col>
         <el-col :span="10">
          <el-form-item label="买家佣金（元）：">
            <el-input v-model="item.buy_price"></el-input>
          </el-form-item>
        </el-col> 
      </el-row>
      <el-form-item>
        <el-button type="primary" size="large" @click="submitClick">确定修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { apiGetTaskMoneyConfig, apiSaveTaskConfig } from '@/utils/api.js'
  export default {
    data() {
      return {
        code: '',
        show: false,
        formList: []
      }
    },
    methods: {
      onShow(code) {
        this.code = code
        this.show = true
        this.apiGetSystemConfigHandel()
      },

      async apiGetSystemConfigHandel() {
        let loading = this.$loading()
        const { success, data } = await apiGetTaskMoneyConfig({ code: this.code}).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.formList = data.list
        console.log(data)
      },

      async submitClick() {
        let loading = this.$loading()
        const { success, data } = await apiSaveTaskConfig({ code: this.code, config: this.formList }).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
        console.log(data)
      },
    }

  }
</script>

<style lang="scss">
.s_task_config_base {
  .s-task-config-base-btn {
    padding-top: 30px;
  }
}
</style>